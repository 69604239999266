import {untilDestroyed} from '@ngneat/until-destroy';
import {DateStringFormField, Error, Form, FormField, IForm, NumberFormField} from '@utils/form';
import {FormGroup} from '@angular/forms';
import {Id} from '@model/entity';
import {Activity} from '@model/activity';
import {QuiverItem, SessionToEnd, SessionToStart} from '@model/schema-model';
import {schemas} from '@model/schemas';
import {EntityType} from '@model/entity-type';

type SessionStartDefaults = Partial<SessionToStart>;

export class SessionStartForm extends Form {
  constructor(formGroup: FormGroup, private defaults: SessionStartDefaults) {
    super(formGroup, schemas.SessionToStart, EntityType.sessions);
  }

  fields = {
    activities: new FormField<Activity[]>(this.schema, this.vf, this.group, this.defaults.activities),
    spotId: new FormField<Id>(this.schema, this.vf, this.group, this.defaults.spotId),
    spotLaunchId: new FormField<Id>(this.schema, this.vf, this.group, this.defaults.spotLaunchId),
    fromInUserTZ: new DateStringFormField(
      this.schema,
      this.vf,
      this.group,
      this.defaults.fromInUserTZ
    ),
    quiver: new FormField<QuiverItem[]>(this.schema, this.vf, this.group, this.defaults.quiver),
    withRiders: new FormField<Id[]>(this.schema, this.vf, this.group, this.defaults.withRiders)
  };
}

type SessionEndDefaults = Partial<SessionToEnd>;

export class SessionEndForm extends Form {
  constructor(formGroup: FormGroup, private defaults: SessionEndDefaults) {
    super(formGroup, schemas.SessionToEnd, EntityType.sessions);
  }

  fields = {
    toInUserTZ: new DateStringFormField(this.schema, this.vf, this.group, this.defaults.toInUserTZ),
    abstract: new FormField<string>(this.schema, this.vf, this.group, this.defaults.abstract),
    score5: new NumberFormField(this.schema, this.vf, this.group, this.defaults.score5),
    distanceKm: new NumberFormField(this.schema, this.vf, this.group, this.defaults.distanceKm),
    maxSpeedKnot: new NumberFormField(this.schema, this.vf, this.group, this.defaults.maxSpeedKnot),
    avgSpeedKnot: new NumberFormField(this.schema, this.vf, this.group, this.defaults.avgSpeedKnot),
    windStability5: new NumberFormField(this.schema, this.vf, this.group, this.defaults.windStability5),
    measuredWindKt: new NumberFormField(this.schema, this.vf, this.group, this.defaults.measuredWindKt),
    measuredWindDirectionDeg: new NumberFormField(this.schema, this.vf, this.group, this.defaults.measuredWindDirectionDeg),
  };
}

export class SessionStartEndForm implements IForm {
  start: SessionStartForm;
  end: SessionEndForm;
  parentEntityType = EntityType.sessions;
  schema = schemas.SessionToUpdate;

  constructor(public group: FormGroup, private defaults: SessionStartDefaults & SessionEndDefaults) {
    this.start = new SessionStartForm(group, defaults);
    this.end = new SessionEndForm(group, defaults);
  }

  init(parent: any): this {
    this.start.init(parent);
    this.end.init(parent);

    // Whenever "fromInUserTZ" date changes, "toInUserTZ" date is updated (but time remains unchanged)
    this.start.fields.fromInUserTZ.valueChanges$
      .pipe(untilDestroyed(parent))
      .subscribe(from => this.end.fields.toInUserTZ.setDateDay(new Date(from)));

    return this;
  }

  get minTo(): Date {
    // "to" must be at least after "from"
    return this.start.fields.fromInUserTZ.dateValue;
  }

  get allErrors(): Error[] {
    return [...this.start.allErrors, ...this.end.allErrors];
  }
}
