import {Component, Input, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Queries} from '@api/queries';
import {Id} from '@model/entity';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';
import {FeedbackService} from '@services/feedback.service';
import {ModalController, Platform} from '@ionic/angular';
import {SessionToUpdate} from '@model/schema-model';
import {FormBuilder} from '@angular/forms';
import {ErrorPipe} from '@pipes/fields/error.pipe';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {IAuthService} from '@services/i-auth.service';
import {FrontendRoutingService} from '@services/frontend-routing.service';
import {EntityType} from '@model/entity-type';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {SessionStartEndForm} from '@components/session/edit-session/session.form';
import {NamedEntity} from '@model/entity-with-description';
import {ICachedRidersService} from '@services/i-cached-riders.service';
import {filterNullish} from '@utils/rxjs.utils';
import {DateUtils} from '@core/date-utils';
import {EditComponent} from '@components/edit/edit-component.directive';
import {getText} from '@model/multi-language-text';
import {TranslateConfigService} from '@services/translate-config.service';
import {nullOrUndefined} from '@core/utils';
import {Commands} from '@services/meteor/commands';
import {RiderAndQuiverItem} from '@api/queries/queries.model';
import {DismissRole} from '@utils/dismissRole';
import {kmPerHToKnots} from '@core/unit-utils';
import { EntityTypePipe } from '@pipes/entity-type.pipe';
import { BroadcastService } from '@services/broadcast.service';
import { FieldLabelType } from '@pipes/fields/field-label.pipe';
import { isNotNullNotUndefined } from '@core/array.utils';

export interface GpxInput {
  fileName: string;
  gpxData: string;
  startDate?: Date;
  endDate?: Date;
  avgSpeedKmPerH?: number;
  maxSpeedKmPerH?: number;
  distanceKm: number;
}

@UntilDestroy()
@Component({
  selector: 'zef-edit-session',
  templateUrl: './edit-session.component.html',
  styleUrls: ['../../../edit/edit.component.scss'] // /!\ notice this is a shared scss
})
export class EditSessionComponent extends EditComponent<SessionStartEndForm> implements OnInit {
  // Either spotId + spotDayDate or sessionId
  @Input() spotId: Id;
  @Input() spotLaunchId: Id;
  @Input() spotDayDate: Date;
  @Input() sessionId: Id;

  @Input() private gpxInput: GpxInput;

  isUpdate: boolean;
  riderQuiver$: Observable<RiderAndQuiverItem[]>;
  showWeatherConditions: boolean;

  private initShowWeatherConditions() {
    this.showWeatherConditions =
      isNotNullNotUndefined(this.form.end.fields.windStability5.value)
      || isNotNullNotUndefined(this.form.end.fields.score5.value)
      || isNotNullNotUndefined(this.form.end.fields.measuredWindDirectionDeg.value)
  }

  constructor(
    queries: Queries,
    commands: Commands,
    feedback: FeedbackService,
    modalController: ModalController,
    platform: Platform,
    errorPipe: ErrorPipe,
    translateService: TranslateService,
    authService: IAuthService,
    entityTypePipe: EntityTypePipe,
    fb: FormBuilder,
    private router: Router,
    private routing: FrontendRoutingService,
    public riders: ICachedRidersService,
    private translateConfig: TranslateConfigService,
    broadcastService: BroadcastService,
  ) {
    super(queries, commands, feedback, modalController, errorPipe, translateService, authService, platform, entityTypePipe, fb, broadcastService);
  }

  ngOnInit() {
    this.isUpdate = !nullOrUndefined(this.sessionId);

    this.riderQuiver$ = this.queries.getRiderAndQuiver
      .call$({_id: this.authService.userId})
      .pipe(
        map(riderAndQuiver => riderAndQuiver.quiver),
        untilDestroyed(this)
      );

    if (this.isUpdate) {
      // EDIT
      this.queries.getSession.call$(this.sessionId).pipe(
        switchMap(session =>
          this.queries.getSpotDay.call$(session.spotDayId).pipe(
            tap(spotDay => {
              const {
                startDate,
                endDate,
                abstract,
                score10,
                windStability10,
                spotLaunchId,
                distanceKm,
                avgSpeedKmPerH,
                maxSpeedKmPerH,
                withRiderIds,
                ...restOfSession
              } = session;
              this.form = new SessionStartEndForm(this.formGroup, {
                spotId: spotDay.spotId,
                fromInUserTZ: this.gpxInput?.startDate ?? DateUtils.considerInTimeZone(startDate, spotDay.timezone, DateUtils.getLocaleTz()),
                toInUserTZ: this.gpxInput?.endDate ?? DateUtils.considerInTimeZone(endDate, spotDay.timezone, DateUtils.getLocaleTz()) ?? new Date(),
                abstract: getText(abstract, this.translateConfig.currentLanguage),
                score5: score10 ? score10 / 2 : undefined,
                windStability5: windStability10 ? windStability10 / 2 : undefined,
                spotLaunchId: this.spotLaunchId ?? spotLaunchId,
                distanceKm: this.gpxInput?.distanceKm ?? distanceKm,
                avgSpeedKnot: kmPerHToKnots(this.gpxInput?.avgSpeedKmPerH ?? avgSpeedKmPerH),
                maxSpeedKnot: kmPerHToKnots(this.gpxInput?.maxSpeedKmPerH ?? maxSpeedKmPerH),
                withRiders: withRiderIds,
                ...restOfSession
              }).init(this);
              this.initShowWeatherConditions();
            }),
            map(() => session) // Return the session
          )
        ),
        untilDestroyed(this)
      ).subscribe();
    } else {
      // CREATE
      let defaultFrom: Date;
      let defaultTo: Date;

      if (this.gpxInput?.startDate) {
        defaultFrom = this.gpxInput?.startDate;
        defaultTo = this.gpxInput?.endDate;
      } else {
        if (this.spotDayDate) {
          // If a spot day is already selected, use this date and set it to the default time of noon
          // The spot day date is in the spot's timezone, but we _consider it_ to be user's timezone
          defaultFrom = new Date(this.spotDayDate.getFullYear(), this.spotDayDate.getMonth(), this.spotDayDate.getDate());
          defaultFrom.setHours(12, 0, 0, 0);
        } else {
          // If no date is specified, consider this is "two hours ago"
          defaultFrom = new Date(new Date().getTime() - 2 * 60000);
          defaultFrom.setMinutes(0, 0, 0);
        }

        defaultTo = new Date(defaultFrom.getTime());
        defaultTo.setHours(defaultFrom.getHours() + 2, 0, 0, 0);
      }

      this.form = new SessionStartEndForm(this.formGroup, {
        spotId: this.spotId,
        spotLaunchId: this.spotLaunchId,
        fromInUserTZ: defaultFrom,
        toInUserTZ: defaultTo,
        distanceKm: this.gpxInput?.distanceKm,
        avgSpeedKnot: kmPerHToKnots(this.gpxInput?.avgSpeedKmPerH),
        maxSpeedKnot: kmPerHToKnots(this.gpxInput?.maxSpeedKmPerH)
      }).init(this);
      this.initShowWeatherConditions();
    }
  }

  protected doSubmit() {
    const data = this.getDataToSubmit();

    // Start with gpx save if needed
    const initialPersist = this.gpxInput
      ? this.commands.insertTrackLog.call$({fileName: this.gpxInput.fileName, data: this.gpxInput.gpxData})
      : of(null);

    // Then save session, with empty or not empty trackLogId
    const persistSession = (trackLogId: Id | undefined) => {
      const updateData: SessionToUpdate = {...data, _id: undefined};

      if (this.isUpdate) {
        updateData._id = this.sessionId;
      }

      // Only set it explicitly, otherwise we might remove it from Mongo accidentally!
      if (trackLogId) {
        updateData.trackLogId = trackLogId;
      }

      return this.isUpdate
        ? this.commands.updateSession.call$(updateData)
        : this.commands.createFullSession.call$(updateData);
    };

    return initialPersist.pipe(
      switchMap((id) => persistSession(id)),
      take(1),
      tap((idOrNumber: Id | number) => this.broadcastService.sendRefresh({
        type: EntityType.sessions,
        id: this.sessionId ?? idOrNumber as Id
      })),
      switchMap(() => this.riders.getEntityById$(this.authService.userId)),
      tap(async ({shortName}: NamedEntity) => {
        await this.feedback.toast(this.translateService.instant(this.isUpdate ? 'SESSION.UPDATE.UPDATED' : 'SESSION.CREATE.CREATED'));
        // Redirect to the appropriate "rider / day"
        const entityLink = {type: EntityType.riders, shortName};
        const path = this.routing.pathToDatedEntity(
          entityLink,
          data.fromInUserTZ
        );
        // Refresh the page
        this.broadcastService.sendRefresh(entityLink);
        await this.router.navigate(path?.link, {fragment: path.fragment});
        await this.modalController.dismiss(undefined, this.isUpdate ? DismissRole.UPDATED : DismissRole.CREATED);
      }),

      untilDestroyed(this)
    ).toPromise()
  }

  protected getDataToSubmit() {
    return {
      activities: this.form.start.fields.activities.value,
      spotId: this.form.start.fields.spotId.value,
      spotLaunchId: this.form.start.fields.spotLaunchId.value,
      fromInUserTZ: this.form.start.fields.fromInUserTZ.dateValue,
      toInUserTZ: this.form.end.fields.toInUserTZ.dateValue,
      userTZ: DateUtils.getLocaleTz(),
      abstract: this.form.end.fields.abstract.value,
      score5: this.form.end.fields.score5.value,
      quiver: this.form.start.fields.quiver.value,
      distanceKm: this.form.end.fields.distanceKm.value,
      maxSpeedKnot: this.form.end.fields.maxSpeedKnot.value,
      avgSpeedKnot: this.form.end.fields.avgSpeedKnot.value,
      withRiders: this.form.start.fields.withRiders.value,
      windStability5: this.form.end.fields.windStability5.value,
      measuredWindKt: this.form.end.fields.measuredWindKt.value,
      measuredWindDirectionDeg: this.form.end.fields.measuredWindDirectionDeg.value,
    };
  }
}
